// extracted by mini-css-extract-plugin
export const navbarTitle = "Navbar-module--navbar--title--3wGew";
export const navbarImg = "Navbar-module--navbar--img--2Mvv_";
export const navbarNav = "Navbar-module--navbar--nav--3z98V";
export const navbarContainer = "Navbar-module--navbar--container--3vBa9";
export const menuButton = "Navbar-module--menu--button--OGIAH";
export const closeButton = "Navbar-module--close--button--1ToaN";
export const navbarDivs = "Navbar-module--navbar--divs--3nElU";
export const active = "Navbar-module--active--2qLkR";
export const dropdown = "Navbar-module--dropdown--30p9_";
export const dropdownContent = "Navbar-module--dropdown-content--28p33";
export const openMenu = "Navbar-module--open-menu--3nnpD";
export const menu = "Navbar-module--menu--26_ZO";
export const buttonEH = "Navbar-module--button-EH--2RcO0";